import { useAuth0 } from '@auth0/auth0-react'

import { pagesPath } from '@/libs/pathpida/$path'

import { Loading } from '@/ui/loading/loading'

import { Redirect } from '@/features/auth/redirect/redirect'

import type { NextPage } from 'next'

const Home: NextPage = () => {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <Loading description={{ type: 'loadingAuth' }} full />
  }

  // 未認証の場合、ログインページにリダイレクト
  if (!isAuthenticated) {
    return <Redirect redirectTo={pagesPath.login.$url()} />
  }

  // 認証済みの場合、受講生一覧ページにリダイレクト
  return <Redirect redirectTo={pagesPath.accounts.$url()} />
}

export default Home
